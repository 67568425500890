<template>
  <div>
    <div class="mx-2">
      <h3 class="title-header noprint mt-5">
        {{
          $getVisibleNames(
            "manual.actividad_presencial",
            true,
            "Actividades Presenciales"
          )
        }}
        <AlternativeNameButton
          :table_name="'manual.actividad_presencial'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'mesh.add_directteachingactivity'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'manual.actividad_presencial',
              false,
              'Actividad Presencial'
            )}`"
            :text_button="`Agregar`"
            :click_button="
              () => $bvModal.show(`new-direct-teaching-activity-modal`)
            "
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'mesh.add_directteachingactivity'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm btn-add"
            @click="$bvModal.show(`new-direct-teaching-activity-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "manual.actividad_presencial",
                false,
                "Actividad Presencial"
              )
            }}
          </button> -->
        </div>
      </div>
      <GenericBTable
        :items="direct_teaching_activities"
        :fields="directTeachingActivityFields"
        :filter="input_search"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'direct_teaching_activities'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(description)="row">
          <div class="rich-text-content" v-html="row.item.description"></div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            v-can="'mesh.change_directteachingactivity'"
            :tooltip_text="`Editar Actividad Presencial`"
            :click_button="
              () =>
                $bvModal.show(
                  `edit-direct-teaching-activity-modal-${row.item.id}`
                )
            "
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            v-can="'mesh.change_directteachingactivity'"
            @click="
              $bvModal.show(
                `edit-direct-teaching-activity-modal-${row.item.id}`
              )
            "
            v-b-tooltip.v-secondary.noninteractive="
              'Editar Actividad Presencial'
            "
          ></button-edit> -->
          <ButtonSmall
            v-can="'mesh.delete_directteachingactivity'"
            :click_button="
              () => askForDeleteDirectTeachingActivity(row.item.id)
            "
            :tooltip_text="`Eliminar Actividad Presencial`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            v-can="'mesh.delete_directteachingactivity'"
            @click="askForDeleteDirectTeachingActivity(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              'Eliminar Actividad Presencial'
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-direct-teaching-activity-modal-${row.item.id}`"
            title="Editar Actividad Presencial"
            size="md"
            hide-footer
          >
            <DirectTeachingActivityForm
              :DirectTeachingActivity="row.item"
              @updated="slotUpdatedDirectTeachingActivity"
              :institution_id="row.item.school"
              :show_title="false"
            ></DirectTeachingActivityForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-direct-teaching-activity-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'manual.actividad_presencial',
        false,
        'Actividad Presencial'
      )}`"
      size="lg"
    >
      <DirectTeachingActivityForm
        @created="slotCreatedDirectTeachingActivity"
        :institution_id="this.user.school"
      ></DirectTeachingActivityForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "DirectTeachingActivityView",
  components: {
    DirectTeachingActivityForm: () => import("./DirectTeachingActivityForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      direct_teaching_activities: names.DIRECT_TEACHING_ACTIVITIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "mesh.add_directteachingactivity"
      );
      if (has_permission) return has_permission;
      else return false;
    },

    directTeachingActivityFields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "manual.actividad_presencial",
              true,
              "Actividades Presenciales"
            )
          ),
          sortable: true,
          tdClass: "text-left",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    slotCreatedDirectTeachingActivity() {
      this.$bvModal.hide("new-direct-teaching-activity-modal");
    },
    slotUpdatedDirectTeachingActivity(direct_teaching_activity) {
      this.$bvModal.hide(
        `edit-direct-teaching-activity-modal-${direct_teaching_activity.id}`
      );
    },
    askForDeleteDirectTeachingActivity(direct_teaching_activity_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Actividad Presencial?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_DIRECT_TEACHING_ACTIVITY,
              direct_teaching_activity_id
            )
            .then(() => {
              toast("Actividad Presencial eliminada.");
            });
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
  },
  created() {
    this.sortBy = "name";
    if (this.user) {
      this.$store.dispatch(
        names.FETCH_DIRECT_TEACHING_ACTIVITIES,
        this.user.school
      );
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [7],
          disabled_selected_intelligence_option: true,
        });
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          intelligence_option: 7,
        });
      });
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.title-header {
  padding: 0 2rem;
}
.btn-add {
  margin-left: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  width: 255px;
}
</style>